import React, { useState, useEffect, useRef, useMemo } from 'react';
import styled from 'styled-components';

import { Colon, CountdownTimerContainer, TablePlaceholder, TimeLeft } from './styles';
import Flex from 'components/Flex';
import TextHeader from 'components/TextHeader';
import { Button, TextEmphasis } from '@protonradio/proton-ui';

import { intercomPaths } from './DiscoveryMode';
import { formatDate } from './helpers';

const StyledTextHeader = styled(TextHeader)`
  text-align: center;
  margin: 1rem 0;
`;

type RemainingTime = {
  days: string;
  hours: string;
  minutes: string;
};

const DEFAULT_REMAINING_TIME: RemainingTime = {
  days: '00',
  hours: '00',
  minutes: '00'
};

export const calculateRemainingTime = (targetDate?: Date): RemainingTime => {
  if (!targetDate) return DEFAULT_REMAINING_TIME;

  const difference = new Date(targetDate).getTime() - new Date().getTime();

  const addLeadingZero = (value: number): string => {
    return value < 10 ? `0${value}` : value.toString();
  };

  if (difference > 0) {
    return {
      days: addLeadingZero(Math.floor(difference / (1000 * 60 * 60 * 24))),
      hours: addLeadingZero(Math.floor((difference / (1000 * 60 * 60)) % 24)),
      minutes: addLeadingZero(Math.floor((difference / 1000 / 60) % 60))
    };
  }

  return DEFAULT_REMAINING_TIME;
};

const CountdownTimer = ({
  title,
  schedulingStartsAt,
  isOptedIn
}: {
  title?: string;
  schedulingStartsAt?: Date;
  isOptedIn?: boolean;
}) => {
  const schedulingStartDate = useMemo(
    () => schedulingStartsAt ?? undefined,
    [schedulingStartsAt]
  );
  const [remaining, setRemaining] = useState<RemainingTime>(
    calculateRemainingTime(schedulingStartDate)
  );

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      setRemaining(calculateRemainingTime(schedulingStartDate));
    }, 60000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [schedulingStartsAt]);

  const subtitleHeaderProps = {
    type: TextHeader.TYPES.MEDIUM,
    weight: TextHeader.WEIGHTS.LIGHT
  };

  return (
    <div data-testid={CountdownTimerTestIds.COUNTDOWN}>
      <TablePlaceholder>
        <CountdownTimerContainer>
          <StyledTextHeader type={TextHeader.TYPES.XLARGE}>
            <TextEmphasis>{title} Campaign</TextEmphasis>
          </StyledTextHeader>

          {schedulingStartDate && (
            <StyledTextHeader
              type={TextHeader.TYPES.LARGE}
              weight={TextHeader.WEIGHTS.NORMAL}
              colorLinks
            >
              <a href={intercomPaths.placeholder}>Eligible tracks</a> will be available on{' '}
              {formatDate(schedulingStartDate)}.
            </StyledTextHeader>
          )}

          <Flex align="baseline" justify="center" className="mt-2 mb-2">
            <div>
              <TimeLeft>{remaining.days}</TimeLeft>
              <StyledTextHeader {...subtitleHeaderProps}>days</StyledTextHeader>
            </div>

            <Colon>:</Colon>

            <div>
              <TimeLeft>{remaining.hours}</TimeLeft>
              <StyledTextHeader {...subtitleHeaderProps}>hours</StyledTextHeader>
            </div>

            <Colon>:</Colon>

            <div>
              <TimeLeft>{remaining.minutes}</TimeLeft>
              <StyledTextHeader {...subtitleHeaderProps}>minutes</StyledTextHeader>
            </div>
          </Flex>
          <StyledTextHeader {...subtitleHeaderProps} className="mb-3">
            <i>
              {isOptedIn
                ? "We'll notify you when your tracks are ready!"
                : 'You must opt in first to use Discovery Mode.'}
            </i>
          </StyledTextHeader>

          <Button variant="secondary" href={intercomPaths.placeholder}>
            Learn more
          </Button>
        </CountdownTimerContainer>
      </TablePlaceholder>
    </div>
  );
};

export const CountdownTimerTestIds = {
  COUNTDOWN: 'DiscoveryMode-CountdownTimer'
};

export default CountdownTimer;
