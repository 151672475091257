import { graphql } from 'gql';

export const DiscoveryModeConfigsQuery = graphql(`
  query DiscoveryModeConfigsQuery {
    viewer {
      id
      artists {
        id
        name
        image {
          url
        }
        configs {
          hasDiscoveryModeEnabled
        }
      }
      configs {
        hasSeenDiscoveryModeOnboarding
      }
    }
  }
`);

export const EligibleTracksFragment = graphql(`
  fragment EligibleTracksFields on DiscoveryModeTrack {
    enabled
    approved
    id
    intentRate
    listeners
    listenersLift
    saves
    spotifyRecommend
    streams
    streamsLift
    track {
      id
      title
      version
      originalArtists {
        id
        name
      }
      featuredArtists {
        id
        name
      }
      remixArtists {
        id
        name
      }
      release {
        id
        coverArt(size: SMALL) {
          url
        }
        label {
          id
          name
        }
      }
    }
  }
`);

export const DiscoveryModeStateQuery = graphql(`
  query DiscoveryMode($filter: DiscoveryModeTrackFilterInput) {
    discoveryMode {
      nextSchedulingStartsAt
      runningCampaign {
        id
        title
        startDate
        endDate
        state
        schedulingStartsAt
        schedulingEndsAt
        eligibleTracks {
          ...EligibleTracksFields
        }
      }
      upcomingCampaign {
        id
        title
        startDate
        endDate
        state
        schedulingStartsAt
        schedulingEndsAt
        eligibleTracks(filterBy: $filter) {
          ...EligibleTracksFields
        }
      }
    }
  }
`);
