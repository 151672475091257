import React, { ComponentType } from 'react';
import { useLocation } from 'react-router-dom';

import Modal from 'components/Modal';
import { PacmanIcon } from 'components/Icons';

import { DiscoveryModeConfigsQuery } from 'graphql/queries/discoveryMode';

import { OnboardingStep } from './OnboardingStep';
import { OptInSuccess } from './SuccessStep';
import { SelectEntities } from './EntitySelectionStep';
import { useQueryWithAlert } from 'hooks/graphql/useQueryWithAlert';
import { StyledModal } from '../styles';
import { DiscoveryModeConfigsQueryQuery } from 'gql/graphql';
import { DiscoveryModeHistoryState } from '../types';

export interface OptInProps {
  viewer: NonNullable<DiscoveryModeConfigsQueryQuery['viewer']>;
  modalLocation: string;
  entityId?: string;
}

type OptInModalSteps = 'dm-opt-in' | 'success' | 'select';

const OptInModalSteps: Record<OptInModalSteps, ComponentType<OptInProps>> = {
  'dm-opt-in': OnboardingStep,
  success: OptInSuccess,
  select: SelectEntities
};

const OptInModal = () => {
  const [{ data, fetching }] = useQueryWithAlert({
    query: DiscoveryModeConfigsQuery
  });
  const location = useLocation<DiscoveryModeHistoryState>();

  const hasOneArtist = data?.viewer?.artists?.length === 1;

  const currentStep = location.state?.step || 'dm-opt-in';
  const StepComponent = OptInModalSteps[currentStep];

  if (fetching || !data?.viewer) {
    return (
      <Modal>
        <StyledModal>
          <PacmanIcon />
        </StyledModal>
      </Modal>
    );
  }

  return (
    <StepComponent
      viewer={data.viewer}
      modalLocation={location.state?.modalLocation || '/discovery-mode'}
      entityId={hasOneArtist ? data.viewer.artists[0].id : location.state?.entityId}
    />
  );
};

export default OptInModal;
