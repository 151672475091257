import {
  DiscoveryModeEnableDisableTrackInput,
  DiscoveryModeTrack,
  EligibleTracksFieldsFragment
} from 'gql/graphql';
import { EnableDisableTrackMutation } from 'graphql/mutations/discoveryMode';
import useMutationWithAlert from 'hooks/graphql/useMutationWithAlert';
import { useAppDispatch } from 'hooks/redux';
import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { showAlert } from 'redux/actions/ui';
import { CombinedError } from 'urql';

type ScheduledTrackState = 'adding' | 'removing' | boolean;
type ScheduledTrackData = {
  id: string;
  img: string;
  title: string;
  artist: string;
  loading: ScheduledTrackState;
  error?: CombinedError | null;
};
type ScheduledTrackLoading = {
  id: string;
  loading: ScheduledTrackState;
  error?: CombinedError | null;
};
type ScheduledTrack = ScheduledTrackData | ScheduledTrackLoading;
export type ScheduledTracksCart = ScheduledTrack[] & { length: 0 | 1 | 2 | 3 };

const isTrackLoaded = (track: ScheduledTrack): track is ScheduledTrackData =>
  track.loading === false;

const createScheduledTrack = (track: DiscoveryModeTrack): ScheduledTrackData => ({
  id: track.track.id,
  img: track.track.release?.coverArt?.url || '',
  title: track.track.title || '',
  artist: track.track.originalArtists[0].name || '',
  loading: false
});

const useScheduledQueue = (eligibleTracklist: EligibleTracksFieldsFragment[]) => {
  const scheduled = eligibleTracklist.filter(track => track.enabled === true);
  const newQueue = useMemo(
    () => scheduled.map(t => createScheduledTrack(t as DiscoveryModeTrack)),
    [scheduled]
  );
  const [queue, setQueue] = useState<ScheduledTracksCart>([]);
  const [, enableDisableTrack] = useMutationWithAlert(EnableDisableTrackMutation);
  const dispatch = useAppDispatch();
  const prevNewQueueRef = useRef(newQueue);

  useEffect(() => {
    //Since React objects are compared by reference, we need to use a deep value comparison to avoid unnecessary rerenders
    if (JSON.stringify(prevNewQueueRef.current) !== JSON.stringify(newQueue)) {
      setQueue(newQueue.slice(0, 3) as ScheduledTracksCart);
      prevNewQueueRef.current = newQueue;
    }
  }, [newQueue]);

  const addToQueue = (pendingTrack: ScheduledTrackLoading) => {
    setQueue(prevTracks => [...prevTracks, pendingTrack] as ScheduledTracksCart);
  };

  const removeFromQueue = (trackId: string) => {
    setQueue(
      prevTracks =>
        prevTracks.filter(
          t => isTrackLoaded(t) && t.id !== trackId
        ) as ScheduledTracksCart
    );
  };

  const updateInQueue = (oldTrack: ScheduledTrack, newTrack: ScheduledTrack) => {
    setQueue(
      prevTracks =>
        prevTracks.map(t =>
          t.id === oldTrack.id ? { ...t, ...newTrack } : t
        ) as ScheduledTracksCart
    );
  };

  const addTrack = async (input: DiscoveryModeEnableDisableTrackInput) => {
    const pendingTrack: ScheduledTrackLoading = {
      loading: 'adding',
      error: null,
      id: input.trackId
    };

    addToQueue(pendingTrack);

    const { data, error, hasErrors } = await enableDisableTrack({
      input: { ...input, enabled: true }
    });

    if (hasErrors || error) {
      removeFromQueue(input.trackId);
      return;
    }

    const result = data?.discoveryModeEnableDisableTrack?.discoveryModeTrack;
    if (result) {
      const scheduledTrack = createScheduledTrack(result as DiscoveryModeTrack);
      updateInQueue(pendingTrack, scheduledTrack);
    }
  };

  const removeTrack = async (input: DiscoveryModeEnableDisableTrackInput) => {
    const trackToRemove = queue.find(t => isTrackLoaded(t) && t.id === input.trackId);
    if (!trackToRemove) {
      dispatch(showAlert('Error removing track, try again later'));
      return;
    }

    updateInQueue(trackToRemove, {
      ...trackToRemove,
      loading: 'removing',
      error: null
    });

    const { data, error, hasErrors } = await enableDisableTrack({
      input: { ...input, enabled: false }
    });

    if (hasErrors || error) {
      updateInQueue(trackToRemove, { ...trackToRemove, loading: false, error });
      return;
    }

    if (data) {
      removeFromQueue(input.trackId);
    }
  };

  const toggleTrack = useCallback(
    async (input: DiscoveryModeEnableDisableTrackInput) => {
      if (input.enabled) {
        return removeTrack(input);
      } else {
        return addTrack(input);
      }
    },
    [queue, removeTrack, addTrack]
  );

  const count = queue.filter(t => isTrackLoaded(t)).length;

  return useMemo(() => ({ queue, count, toggleTrack }), [queue, toggleTrack]);
};

export default useScheduledQueue;
