import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Select, SearchInput, ResponsiveSelect, COLORS } from '@protonradio/proton-ui';
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import useCookie from 'react-use-cookie';
import {
  DiscoveryModeEntity,
  discoveryModeQueryParams,
  useDiscoveryMode
} from './DiscoveryModeContext';
import { hideIntercomLauncher, showIntercomLauncher } from 'config/intercom';
import { BREAKPOINTS } from 'config/constants/styles';

const PREFERRED_ENTITY_COOKIE = 'preferred_discovery_mode_entity';

/**
 * Hook that manages which entity is selected in the Discovery Mode dashboard.
 *
 * The selected entity is:
 * 1. Retrieved from a cookie if one exists
 * 2. Set to the first opted-in entity if available
 * 3. Defaults to the first entity in the list if no other options exist
 *
 * The selection is persisted in a cookie for future visits.
 */
const usePreferredEntity = (
  updateParams: (params: { e: string }) => void,
  entities?: DiscoveryModeEntity[]
) => {
  const [preferredEntity, setPreferredEntity] = useCookie(PREFERRED_ENTITY_COOKIE, '');
  const { e: entityParam } = useUrlQueryParams(discoveryModeQueryParams);

  useEffect(() => {
    if (!entities?.length) return;

    const isValidCookie = entities.some(entity => entity.name === preferredEntity);

    if (isValidCookie && !entityParam) {
      updateParams({ e: preferredEntity });
      return;
    }

    if (!isValidCookie) {
      const defaultEntity =
        entities.find(entity => entity.isOptedIn)?.name || entities[0]?.name;

      if (defaultEntity) {
        setPreferredEntity(defaultEntity);
        updateParams({ e: defaultEntity });
      }
    }
  }, [entities, preferredEntity, entityParam]);

  return { preferredEntity, setPreferredEntity };
};

const BaseSelectWrapper = styled.div`
  min-width: 200px;
  button {
    min-height: 40px;
    padding: 1rem 1.5rem;
  }

  @media (max-width: ${BREAKPOINTS.MD}px) {
    max-width: 200px;
  }
`;

const BaseSearchWrapper = styled.div`
  width: 100%;
  min-width: 340px;

  @media (max-width: ${BREAKPOINTS.MD}px) {
    width: 100%;
  }
`;

const StyledFlex = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media (max-width: ${BREAKPOINTS.MD}px) {
    flex-direction: column;
    background-color: ${COLORS.GRAYSCALE.GRAY_SUPER_LIGHT};
    border-bottom: 1px solid #dddddd;
    overflow: hidden;
    position: sticky;
    top: 44px;
    z-index: 1;
    padding: 1rem 10px 1rem 10px;
    margin: 0 -20px 0 -20px;
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    padding: 1rem 10px 1rem 10px;
    margin: 0 -10px 0 -10px;
  }
`;

const DiscoveryModeFilterControls = () => {
  const { loading, entities, eligibleTracklist, enabledTracklist } = useDiscoveryMode();
  const { q: searchQuery, updateParams } = useUrlQueryParams(discoveryModeQueryParams);
  const { preferredEntity, setPreferredEntity } = usePreferredEntity(
    updateParams,
    entities
  );
  const isEnabledRoute = window.location.pathname.includes('enabled');

  const options = entities.map(entity => ({
    label: entity.name,
    value: entity.name
  }));

  const isLoading = !searchQuery && loading;

  return (
    <StyledFlex>
      <BaseSearchWrapper>
        <SearchInput
          defaultValue={searchQuery as string}
          onChange={value => updateParams({ q: value })}
          onClear={() => updateParams({ q: undefined })}
          name="discovery-mode-track-search"
          placeholder={
            isLoading
              ? 'Loading...'
              : isEnabledRoute
                ? `Search ${enabledTracklist.length} Enabled Tracks`
                : `Search ${eligibleTracklist.length} Eligible Tracks`
          }
        />
      </BaseSearchWrapper>
      <BaseSelectWrapper>
        <ResponsiveSelect.Menu
          name="discovery-mode-artist-filter"
          items={options}
          selectedKey={preferredEntity}
          onOpen={hideIntercomLauncher}
          onClose={showIntercomLauncher}
          onSelectionChange={(selected: string) => {
            updateParams({ e: selected });
            setPreferredEntity(selected);
          }}
        >
          {item => <Select.Option key={item.value}>{item.label}</Select.Option>}
        </ResponsiveSelect.Menu>
      </BaseSelectWrapper>
    </StyledFlex>
  );
};

export default DiscoveryModeFilterControls;
