import { Banner } from '@protonradio/proton-ui';
import { COLORS } from '@protonradio/proton-ui/constants';
import { BREAKPOINTS } from 'config/constants';
import styled from 'styled-components';

/* Global Components */

export const TablePlaceholder = styled.div`
  margin-top: 1rem;
  background-color: ${COLORS.GRAYSCALE.GRAY_SUPER_LIGHT};
  height: 100%;
  width: 100%;
  padding: 2rem;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/* Header Components */

export const GradientGreenBackground = styled.div`
  background: ${COLORS.GRAYSCALE.GRAY_SUPER_DARK};
  background-image: linear-gradient(
    270deg,
    rgba(67, 160, 71, 0.43) 1%,
    rgba(0, 0, 0, 0.5) 30%
  );
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
`;

export const LogoTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;

  @media (max-width: ${BREAKPOINTS.LG}px) {
    margin-top: 4rem;
  }

  span {
    margin-left: 1.6rem;
  }

  svg {
    @media (max-width: ${BREAKPOINTS.SM}px) {
      width: 0;
      height: 0;
    }

    @media (min-width: ${BREAKPOINTS.SM}px) and (max-width: ${BREAKPOINTS.MD}px) {
      width: 52px;
      height: 52px;
      margin-right: -0.6rem;
    }

    @media (min-width: ${BREAKPOINTS.MD}px) and (max-width: ${BREAKPOINTS.LG}px) {
      width: 68px;
      height: 68px;
    }

    @media (min-width: ${BREAKPOINTS.LG}px) and (max-width: ${BREAKPOINTS.XL}px) {
      width: 72px;
      height: 72px;
    }

    @media (min-width: ${BREAKPOINTS.XL}px) {
      width: 78px;
      height: 78px;
    }
  }
`;

export const BannerWrapper = styled.div`
  margin-bottom: 1rem;

  @media (max-width: ${BREAKPOINTS.MD}px) {
    margin: 0 -20px 0 -20px;
  }

  @media (max-width: ${BREAKPOINTS.SM}px) {
    margin: 0 -10px 0 -10px;
  }
`;

/* Countdown Components */

export const CountdownTimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
`;

export const TimeLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10rem;
  height: 8rem;
  background-color: ${COLORS.GRAYSCALE.WHITE};
  border-radius: 1rem;
  border: 0.1rem solid ${COLORS.GRAYSCALE.GRAY_LIGHTEST};

  font-size: 4rem;
  font-weight: 700;
  color: ${COLORS.BRAND.PRIMARY};
  letter-spacing: 0.2rem;
  text-align: center;
  line-height: 1;

  @media (max-width: ${BREAKPOINTS.SM}px) {
    width: 8rem;
    height: 6rem;
    font-size: 3rem;
  }
`;

export const Colon = styled.div`
  margin: 0 1rem;
  font-size: 4rem;
  font-weight: 700;
  color: ${COLORS.BRAND.PRIMARY};

  @media (max-width: ${BREAKPOINTS.SM}px) {
    margin: 0 0.4rem;
    font-size: 3rem;
  }
`;

/* Sidebar Components */

export const CalendarBackground = styled.div`
  background-color: ${Banner.colors.danger.icon};
  height: 21px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.2rem;
`;

/* Cart Components */

export const CartItem = styled.div`
  width: 100%;
  padding: 1rem;
  width: 80px;
  height: 80px;

  &:first-child {
    border: 1px solid ${COLORS.GRAYSCALE.BORDER};
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }

  &:nth-child(2) {
    border-top: 1px solid ${COLORS.GRAYSCALE.BORDER};
    border-bottom: 1px solid ${COLORS.GRAYSCALE.BORDER};
  }

  &:last-child {
    border: 1px solid ${COLORS.GRAYSCALE.BORDER};
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 20px;
  height: 20px;
  background-color: ${COLORS.GRAYSCALE.GRAY_MEDIUM_LIGHT};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  opacity: 0;
  transition:
    opacity,
    background-color,
    0.1s ease-in-out;

  &:hover {
    background-color: ${COLORS.GRAYSCALE.GRAY_MEDIUM};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:hover #remove-button {
    opacity: 1;
  }
`;

/* Table Components */

export const TableWrapper = styled.div<{ $isDisabled: boolean }>`
  width: 100%;
  opacity: ${props => (props.$isDisabled ? 0.5 : 1)};
`;

/* Mobile Table Components */

export const MobileRowWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 61px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.GRAYSCALE.GRAY_LIGHT};
`;

export const StyledMobileTableHeader = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 30px;
  background-color: ${COLORS.GRAYSCALE.GRAY_LIGHT};
  font-size: 10px;

  @media (max-width: ${BREAKPOINTS.MEDIUM}px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media (max-width: ${BREAKPOINTS.SMALL}px) {
    margin-left: -10px;
    margin-right: -10px;
  }
`;
