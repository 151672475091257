import Bugsnag from '@bugsnag/js';
import { devtoolsExchange } from '@urql/devtools';
import { authExchange } from '@urql/exchange-auth';
import ahoy from 'ahoy.js';
import { OperationDefinitionNode } from 'graphql';
import { getCookie } from 'react-use-cookie';
import {
  AnyVariables,
  OperationResult,
  createClient,
  fetchExchange,
  mapExchange
} from 'urql';
import { cacheExchange, Cache, Variables } from '@urql/exchange-graphcache';

import { API_GRAPHQL_BASE, FINGERPRINT_COOKIE } from 'config/constants';
import { EVENTS, ProtonEventEmitter } from 'config/events';
import { clearAuthCookies, getCurrentAuthCookies, setAuthCookies } from 'helpers';
import { refreshToken } from './token';
import { CurrentUserQuery } from 'hooks/useCurrentUser';

const invalidateCachedData = (fieldName: string, args: Variables, cache: Cache) => {
  cache.invalidate({
    __typename: 'Query',
    fieldName: fieldName,
    arguments: args
  });
};

export const getClient = (_isLoggedIn: boolean | null, logout: () => void) => {
  return createClient({
    url: API_GRAPHQL_BASE,
    exchanges: [
      devtoolsExchange,
      cacheExchange({
        updates: {
          Mutation: {
            discoveryModeOptInOut: (_result, args, cache) => {
              invalidateCachedData('viewer', args, cache);
            },
            userSettingUpdate: (result, _args, cache) => {
              const updatedSetting = (result.userSettingUpdate as any)?.userSetting;
              if (!updatedSetting) return;

              cache.updateQuery({ query: CurrentUserQuery }, data => {
                if (!data?.viewer) return data;

                return {
                  ...data,
                  viewer: {
                    ...data.viewer,
                    settings: data.viewer.settings.map(setting =>
                      setting.settingType === updatedSetting.settingType
                        ? updatedSetting
                        : setting
                    )
                  }
                };
              });
            },
            archiveAccessGrant: (_result, args, cache) =>
              invalidateCachedData('label', { id: args.labelId }, cache),
            archiveAccessRenew: (_result, args, cache) =>
              invalidateCachedData('label', { id: args.labelId }, cache),
            archiveAccessRevoke: (_result, args, cache) =>
              invalidateCachedData('label', { id: args.labelId }, cache)
          }
        }
      }),
      mapExchange({
        // leave breadcrumb of results if bugsnag error instance
        onResult: <Data>(result: OperationResult<Data, AnyVariables>) => {
          if (result.error) {
            const mfaError = result.error.graphQLErrors.find(
              ({ extensions }) => extensions.code === 'MFA_REQUIRED'
            );

            if (mfaError) {
              const requestToken = mfaError.extensions.token;
              ProtonEventEmitter.emit(EVENTS.MFA_REQUIRED, requestToken);
            }
          }

          const metadata = {
            query: result.operation.query.definitions.map(
              (def: OperationDefinitionNode) => def.name?.value
            ),
            variables: result.operation.variables,
            error: result.error,
            data: result.data
          };
          Bugsnag.leaveBreadcrumb('GraphQL Response', metadata, 'manual');
        }
      }),
      authExchange(utils => {
        const { jwt } = getCurrentAuthCookies();
        return Promise.resolve({
          addAuthToOperation(operation) {
            if (!jwt) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${jwt}`
            });
          },
          didAuthError(error, _operation) {
            return error.graphQLErrors.some(
              e =>
                e.extensions.code === 'NOT_AUTHORIZED' &&
                e.message.includes('not logged in')
            );
          },
          async refreshAuth() {
            if (!jwt) return;
            const token = await refreshToken();
            if (token.jwt) {
              setAuthCookies(token.jwt, token.refresh_token || '');
            } else {
              clearAuthCookies();
              logout();
            }
          }
        });
      }),
      fetchExchange
    ],
    fetchOptions: () => {
      const ahoyHeaders = {};
      if (ahoy.getVisitToken()) {
        ahoyHeaders['Ahoy-Visit'] = ahoy.getVisitToken();
      }
      if (ahoy.getVisitorToken()) {
        ahoyHeaders['Ahoy-Visitor'] = ahoy.getVisitorToken();
      }
      return {
        headers: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          'x-fingerprint-visitor-id': getCookie(FINGERPRINT_COOKIE),
          ...ahoyHeaders
        }
      };
    }
  });
};
