import React from 'react';

import { Cell, Column, Row, Table, TableBody, TableHeader } from '@protonradio/proton-ui';

import { DiscoveryModeQuery, EligibleTracksFieldsFragment } from 'gql/graphql';
import { useDiscoveryMode } from './DiscoveryModeContext';
import CommaList from 'components/CommaList';
import Flex from 'components/Flex';
import {
  MobileRowWrapper,
  StyledMobileTableHeader,
  TablePlaceholder,
  TableWrapper
} from './styles';
import Icon from 'components/Icon';
import useBreakpoint from 'hooks/useBreakpoint';
import { ConditionalDash, TrackCellData, TrackScheduleButton } from './TableComponents';

interface EligibleTracksTableProps {
  isDisabled: boolean;
  tracklist: EligibleTracksFieldsFragment[];
  campaign: {
    title: string;
    id: string;
  };
}

const DesktopEligibleTracksTable = ({
  tracklist,
  campaign,
  isDisabled
}: EligibleTracksTableProps) => {
  const { toggleTrack } = useDiscoveryMode().scheduledQueue;
  return (
    <TableWrapper $isDisabled={isDisabled}>
      <Table>
        <TableHeader>
          <Column>Track</Column>
          <Column>Label</Column>
          <Column>Remix Artists</Column>
          <Column>Intent Rate</Column>
          <Column>Scheduled for {campaign.title}</Column>
          <Column>{''}</Column>
        </TableHeader>
        <TableBody>
          {tracklist?.map((rowData, index) => {
            const performance = rowData;
            const track = performance.track;

            return (
              <Row key={index + 1} data-testid="row">
                <Cell>
                  <TrackCellData trackCampaignData={rowData} />
                </Cell>
                <Cell>{track.release?.label?.name}</Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(track.remixArtists.length)}>
                    <CommaList data={track.remixArtists.map(a => a.name)} />
                  </ConditionalDash>
                </Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(performance.intentRate)}>
                    <Flex align="center">
                      {performance.intentRate}%
                      <Icon
                        type={Icon.TYPES.TROPHY}
                        color={'gold'}
                        width={16}
                        className="ml-1"
                      />
                    </Flex>
                  </ConditionalDash>
                </Cell>
                <Cell>
                  <ConditionalDash condition={Boolean(performance.enabled)}>
                    <Icon
                      type={Icon.TYPES.CHECK_MARK}
                      color={Icon.COLORS.PRIMARY}
                      width={16}
                    />
                  </ConditionalDash>
                </Cell>

                <Cell>
                  <Flex justify="flex-end">
                    <Flex justify="flex-end" style={{ maxWidth: '100px' }}>
                      <TrackScheduleButton
                        isDisabled={isDisabled}
                        trackCampaignData={rowData}
                        onPress={() =>
                          toggleTrack({
                            campaignId: campaign.id,
                            trackId: track.id,
                            enabled: performance.enabled,
                            artistId: track.originalArtists[0].id
                          })
                        }
                      />
                    </Flex>
                  </Flex>
                </Cell>
              </Row>
            );
          })}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const MobileEligibleTracksTable = ({
  tracklist,
  campaign,
  isDisabled
}: EligibleTracksTableProps) => {
  const { toggleTrack } = useDiscoveryMode().scheduledQueue;
  return (
    <>
      <StyledMobileTableHeader className="proton-Table__header">
        Scheduled for {campaign.title}
      </StyledMobileTableHeader>
      {tracklist.map((rowData, index) => (
        <MobileRowWrapper key={index}>
          <TrackCellData trackCampaignData={rowData} />
          <TrackScheduleButton
            isDisabled={isDisabled}
            trackCampaignData={rowData}
            onPress={() => {
              toggleTrack({
                campaignId: campaign.id,
                trackId: rowData.track.id,
                enabled: rowData.enabled,
                artistId: rowData.track.originalArtists[0].id
              });
            }}
          />
        </MobileRowWrapper>
      ))}
    </>
  );
};

const EligibleTracksTable = ({ isDisabled }: { isDisabled: boolean }) => {
  const { eligibleTracklist: tracklist, discoveryMode } = useDiscoveryMode();
  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.MEDIUM,
    useBreakpoint.DIRECTIONS.DOWN
  );

  if (!tracklist || tracklist?.length === 0) {
    return (
      <div className="mt-2" data-testid="DiscoveryMode-Eligible-Table">
        <TablePlaceholder>No tracks found</TablePlaceholder>
      </div>
    );
  }

  const tableProps: EligibleTracksTableProps = {
    tracklist,
    isDisabled,
    campaign: {
      title: discoveryMode?.upcomingCampaign?.title || '',
      id: discoveryMode?.upcomingCampaign?.id || ''
    }
  };

  return !isSmallScreen ? (
    <DesktopEligibleTracksTable {...tableProps} />
  ) : (
    <MobileEligibleTracksTable {...tableProps} />
  );
};

const EligibleTracks = ({
  countdownTimer,
  isDisabled
}: {
  countdownTimer: React.ReactNode;
  isDisabled: boolean;
}) => {
  if (countdownTimer) return countdownTimer;

  return (
    <EligibleTracksTable
      data-testid="DiscoveryMode-Eligible-Table"
      isDisabled={isDisabled}
    />
  );
};

export default EligibleTracks;
