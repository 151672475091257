/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  mutation updateUserSettings ($input: UserSettingUpdateInput!) {\n    userSettingUpdate (input: $input) {\n      errorDetails {\n        message\n      }\n      userSetting {\n        enabled\n        settingType\n        value\n      }\n    }\n  }\n':
    types.UpdateUserSettingsDocument,
  '\n  mutation discoveryModeOnboardingSeenUpdate {\n    discoveryModeOnboardingSeenUpdate(input: {}) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n':
    types.DiscoveryModeOnboardingSeenUpdateDocument,
  '\n  mutation discoveryModeOptInOut($artistId: ID, $labelId: ID, $enabled: Boolean!) {\n    discoveryModeOptInOut(\n      input: { artistId: $artistId, labelId: $labelId, enabled: $enabled }\n    ) {\n      enabled\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n':
    types.DiscoveryModeOptInOutDocument,
  '\n  mutation EnabledDisableTrack($input: DiscoveryModeEnableDisableTrackInput!) {\n    discoveryModeEnableDisableTrack(input: $input) {\n      discoveryModeTrack {\n        id\n        enabled\n        track {\n          id\n          title\n          version\n          originalArtists {\n            id\n            name\n          }\n          release {\n            id\n            coverArt(size: SMALL) {\n              url\n            }\n          }\n        }\n      }\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n':
    types.EnabledDisableTrackDocument,
  '\n  mutation addChannel($input: CommunicationChannelAddInput!) {\n    communicationChannelAdd(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n':
    types.AddChannelDocument,
  '\n  query getUserCommunicationChannels {\n    viewer {\n      id\n      communicationChannels {\n        id\n        channel\n        value\n        isVerified\n      }\n    }\n  }\n':
    types.GetUserCommunicationChannelsDocument,
  '\n  mutation deliverOtp($input: CommunicationChannelDeliverOtpInput!) {\n    communicationChannelDeliverOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n':
    types.DeliverOtpDocument,
  '\n  mutation verifyOtp($input: CommunicationChannelVerifyOtpInput!) {\n    communicationChannelVerifyOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n':
    types.VerifyOtpDocument,
  '\n  query DiscoveryModeConfigsQuery {\n    viewer {\n      id\n      artists {\n        id\n        name\n        image {\n          url\n        }\n        configs {\n          hasDiscoveryModeEnabled\n        }\n      }\n      configs {\n        hasSeenDiscoveryModeOnboarding\n      }\n    }\n  }\n':
    types.DiscoveryModeConfigsQueryDocument,
  '\n  fragment EligibleTracksFields on DiscoveryModeTrack {\n    enabled\n    approved\n    id\n    intentRate\n    listeners\n    listenersLift\n    saves\n    spotifyRecommend\n    streams\n    streamsLift\n    track {\n      id\n      title\n      version\n      originalArtists {\n        id\n        name\n      }\n      featuredArtists {\n        id\n        name\n      }\n      remixArtists {\n        id\n        name\n      }\n      release {\n        id\n        coverArt(size: SMALL) {\n          url\n        }\n        label {\n          id\n          name\n        }\n      }\n    }\n  }\n':
    types.EligibleTracksFieldsFragmentDoc,
  '\n  query DiscoveryMode($filter: DiscoveryModeTrackFilterInput) {\n    discoveryMode {\n      nextSchedulingStartsAt\n      runningCampaign {\n        id\n        title\n        startDate\n        endDate\n        state\n        schedulingStartsAt\n        schedulingEndsAt\n        eligibleTracks {\n          ...EligibleTracksFields\n        }\n      }\n      upcomingCampaign {\n        id\n        title\n        startDate\n        endDate\n        state\n        schedulingStartsAt\n        schedulingEndsAt\n        eligibleTracks(filterBy: $filter) {\n          ...EligibleTracksFields\n        }\n      }\n    }\n  }\n':
    types.DiscoveryModeDocument,
  '\n  mutation removeSubscriber($labelId: ID!, $artistId: ID!) {\n    labelSubscriberRemove(input: { labelId: $labelId, artistId: $artistId }) {\n      artist {\n        id\n      }\n      errorDetails {\n        path\n        message\n      }\n    }\n  }\n':
    types.RemoveSubscriberDocument,
  '\n  mutation inviteSubscriber($labelId: ID!, $artistId: ID!) {\n    labelSubscriberInvite(input: { labelId: $labelId, artistId: $artistId }) {\n      artist {\n        id\n      }\n      errorDetails {\n        path\n        message\n      }\n    }\n  }\n':
    types.InviteSubscriberDocument,
  '\n  query getLabelAndViewer($labelId: ID!) {\n    label(id: $labelId) {\n      id\n      name\n      activeEndYear\n      activeStartYear\n      promoAccessState\n      hasPromoAccess\n      description\n      slug\n      viewer {\n        id\n        hasReleasesOnLabel\n        isLabelManager\n      }\n      genres {\n        name\n      }\n      logo(size: MEDIUM) {\n        url\n      }\n    }\n    viewer {\n      id\n      promoPool {\n        labels(filterBy: { labelId: { eq: $labelId } }) {\n          edges {\n            id\n            addedAt\n            state\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetLabelAndViewerDocument,
  '\n  query getReleaseDSPInfo($id: ID!) {\n    release(id: $id) {\n      storeInfo {\n        name\n        url\n        status\n        __typename\n      }\n    }\n  }\n':
    types.GetReleaseDspInfoDocument,
  '\n  query getCurrentUser {\n    viewer {\n      id\n      username\n      token\n      managedLabels {\n        id\n        name\n      }\n      privacyPolicyConsents {\n        id\n        version\n        consentedAt\n      }\n      roles {\n        admin\n        artist\n        curator\n        labelManager\n        showHost\n      }\n      permissions {\n        accessPromoPool\n        proUser\n      }\n      settings {\n        enabled\n        settingType\n        value\n      }\n    }\n  }\n':
    types.GetCurrentUserDocument,
  '\n  mutation privacyPolicyConsent($version: ID!) {\n    privacyPolicyConsent(input: { version: $version }) {\n      privacyPolicyConsents {\n        id\n        version\n      }\n      errors\n    }\n  }\n':
    types.PrivacyPolicyConsentDocument,
  '\n  mutation updateReleaseLinkState($input: ReleaseLinkStateUpdateInput!) {\n    releaseLinkStateUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      labelConfigs {\n        releaseLinkState\n      }\n    }\n  }\n':
    types.UpdateReleaseLinkStateDocument,
  '\n  mutation releaseLinkNotificationConfigUpdate($input: NotificationConfigUpdateInput!) {\n    releaseLinkNotificationConfigUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      config {\n        id\n      }\n    }\n  }\n':
    types.ReleaseLinkNotificationConfigUpdateDocument,
  '\n  mutation communicationChannelAdd($input: CommunicationChannelAddInput!) {\n    communicationChannelAdd(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        isVerified\n        value\n      }\n    }\n  }\n':
    types.CommunicationChannelAddDocument,
  '\n  mutation communicationChannelDeliverOtp($input: CommunicationChannelDeliverOtpInput!) {\n    communicationChannelDeliverOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        isVerified\n        value\n      }\n    }\n  }\n':
    types.CommunicationChannelDeliverOtpDocument,
  '\n  query viewerNotificationConfigs {\n    viewer {\n      id\n      proUser {\n        id\n        country\n      }\n      notificationConfigs {\n        artist {\n          id\n          name\n        }\n        label {\n          id\n          name\n          configs {\n            releaseLinkFeatureEnabled\n            releaseLinkState\n          }\n        }\n        communicationChannel {\n          id\n          channel\n          value\n          isVerified\n          emailField\n        }\n        feature\n        enabled\n      }\n      communicationChannels {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n':
    types.ViewerNotificationConfigsDocument,
  '\n  query GetPaymentSettings {\n    viewer {\n      id\n      hasPaymentPending\n      configuredPaymentMethods {\n        id\n        isValid\n        default\n        token\n        address\n        network\n        overrideRestriction\n        needsConfirmation\n        preferredPaymentProvider {\n          id\n        }\n        paymentMethod {\n          id\n          name\n          status\n          automatic\n        }\n      }\n      paymentMethods {\n        edges {\n          enabled\n          message\n          node {\n            id\n            name\n            status\n            allowOverride\n            automatic\n            needsConfirmation\n            paymentProviders {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetPaymentSettingsDocument,
  '\n  query releaseLinkOnboardingStatus($labelId: ID!) {\n    label(id: $labelId) {\n      id\n      name\n      viewer {\n        shouldDisplayReleaseLinkOnboarding\n      }\n    }\n  }\n':
    types.ReleaseLinkOnboardingStatusDocument,
  '\n  mutation releaseLinkOnboardingSeen($input: ReleaseLinkOnboardingSeenUpdateInput!) {\n    releaseLinkOnboardingSeenUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      viewer {\n        id\n        configs {\n          hasSeenReleaseLinkOnboarding\n        }\n      }\n    }\n  }\n':
    types.ReleaseLinkOnboardingSeenDocument,
  '\n  query ReleaseArtists($releaseId: ID!) {\n    release(id: $releaseId) {\n      headlineArtists {\n        id\n        name\n      }\n    }\n  }\n':
    types.ReleaseArtistsDocument,
  '\n  query PromoLabelInvites(\n    $page: Int\n    $perPage: Int\n    $filterBy: PromoSubscriptionFilterInput\n    $orderBy: PromoSubscriptionOrderInput\n    $status: LabelSubscriptionEnum\n  ) {\n    viewer {\n      id\n      promoPool {\n        labels(\n          page: $page\n          perPage: $perPage\n          filterBy: $filterBy\n          orderBy: $orderBy\n          status: $status\n        ) {\n          pageInfo {\n            pageCount\n            nodeCount\n            hasNextPage\n            hasPreviousPage\n            __typename\n          }\n          edges {\n            __typename\n            id\n            addedAt\n            hasArchiveAccess\n            state\n            node {\n              id\n              name\n              slug\n              logo(size: THUMB) {\n                url\n              }\n              topArtists {\n                id\n                name\n              }\n              genres {\n                id\n                name\n              }\n              beatportFollowerCount\n              soundcloudFollowerCount\n              __typename\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.PromoLabelInvitesDocument,
  '\n  fragment ArchiveAccessFields on ArtistLabelSubscription {\n    archiveAccessExpiresAt\n    archiveAccessGrantedAt\n    hasArchiveAccess\n    label {\n      id\n      name\n    }\n  }\n':
    types.ArchiveAccessFieldsFragmentDoc,
  '\n  mutation grantArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessGrant(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n':
    types.GrantArchiveAccessDocument,
  '\n  mutation renewArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessRenew(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n':
    types.RenewArchiveAccessDocument,
  '\n  mutation revokeArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessRevoke(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n':
    types.RevokeArchiveAccessDocument,
  '\n  query getLabelSubscribers(\n    $labelId: ID!\n    $page: Int\n    $perPage: Int\n    $sortBy: LabelSubscriberOrderInput\n    $filterBy: LabelSubscriberFilterInput\n  ) {\n    label(id: $labelId) {\n      id\n      subscribers(page: $page, perPage: $perPage, filterBy: $filterBy, orderBy: $sortBy) {\n        pageInfo {\n          page\n          pageCount\n          nodeCount\n          perPage\n          hasNextPage\n        }\n        edges {\n          id\n          addedAt\n          lastReactedAt\n          hasArchiveAccess\n          archiveAccessExpiresAt\n          archiveAccessGrantedAt\n          averageRating\n          hasPromoAccess\n          isFollowing\n          node {\n            id\n            name\n            slug\n            spotifyPopularity\n            image {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.GetLabelSubscribersDocument,
  '\n  query getRelease($id: ID!) {\n    release(id: $id) {\n      uid\n      id\n      name\n      description\n      date\n      slug\n      preorderDate\n      headlineArtists {\n        id\n        name\n        slug\n      }\n      permissions {\n        viewFeedback\n      }\n      label {\n        id\n        name\n        slug\n      }\n      coverArt(size: MEDIUM) {\n        url\n      }\n      viewer {\n        isLabelManager\n        isArtistMember\n      }\n      ...ReleaseDetailsFields\n      ...MobileButtonsReleaseFields\n    }\n  }\n':
    types.GetReleaseDocument,
  '\n  fragment ReleaseDetailsFields on Release {\n    date\n    description\n    label {\n      name\n      slug\n    }\n    genres {\n      name\n      id\n      occurrences\n    }\n    storeInfo {\n      name\n      url\n      status\n    }\n  }\n':
    types.ReleaseDetailsFieldsFragmentDoc,
  '\n  fragment MobileButtonsReleaseFields on Release {\n    id\n    date\n    label {\n      name\n      slug\n    }\n  }\n':
    types.MobileButtonsReleaseFieldsFragmentDoc
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateUserSettings ($input: UserSettingUpdateInput!) {\n    userSettingUpdate (input: $input) {\n      errorDetails {\n        message\n      }\n      userSetting {\n        enabled\n        settingType\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation updateUserSettings ($input: UserSettingUpdateInput!) {\n    userSettingUpdate (input: $input) {\n      errorDetails {\n        message\n      }\n      userSetting {\n        enabled\n        settingType\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation discoveryModeOnboardingSeenUpdate {\n    discoveryModeOnboardingSeenUpdate(input: {}) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation discoveryModeOnboardingSeenUpdate {\n    discoveryModeOnboardingSeenUpdate(input: {}) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation discoveryModeOptInOut($artistId: ID, $labelId: ID, $enabled: Boolean!) {\n    discoveryModeOptInOut(\n      input: { artistId: $artistId, labelId: $labelId, enabled: $enabled }\n    ) {\n      enabled\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation discoveryModeOptInOut($artistId: ID, $labelId: ID, $enabled: Boolean!) {\n    discoveryModeOptInOut(\n      input: { artistId: $artistId, labelId: $labelId, enabled: $enabled }\n    ) {\n      enabled\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation EnabledDisableTrack($input: DiscoveryModeEnableDisableTrackInput!) {\n    discoveryModeEnableDisableTrack(input: $input) {\n      discoveryModeTrack {\n        id\n        enabled\n        track {\n          id\n          title\n          version\n          originalArtists {\n            id\n            name\n          }\n          release {\n            id\n            coverArt(size: SMALL) {\n              url\n            }\n          }\n        }\n      }\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation EnabledDisableTrack($input: DiscoveryModeEnableDisableTrackInput!) {\n    discoveryModeEnableDisableTrack(input: $input) {\n      discoveryModeTrack {\n        id\n        enabled\n        track {\n          id\n          title\n          version\n          originalArtists {\n            id\n            name\n          }\n          release {\n            id\n            coverArt(size: SMALL) {\n              url\n            }\n          }\n        }\n      }\n      errors\n      errorDetails {\n        message\n        path\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation addChannel($input: CommunicationChannelAddInput!) {\n    communicationChannelAdd(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation addChannel($input: CommunicationChannelAddInput!) {\n    communicationChannelAdd(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getUserCommunicationChannels {\n    viewer {\n      id\n      communicationChannels {\n        id\n        channel\n        value\n        isVerified\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getUserCommunicationChannels {\n    viewer {\n      id\n      communicationChannels {\n        id\n        channel\n        value\n        isVerified\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation deliverOtp($input: CommunicationChannelDeliverOtpInput!) {\n    communicationChannelDeliverOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation deliverOtp($input: CommunicationChannelDeliverOtpInput!) {\n    communicationChannelDeliverOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation verifyOtp($input: CommunicationChannelVerifyOtpInput!) {\n    communicationChannelVerifyOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation verifyOtp($input: CommunicationChannelVerifyOtpInput!) {\n    communicationChannelVerifyOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DiscoveryModeConfigsQuery {\n    viewer {\n      id\n      artists {\n        id\n        name\n        image {\n          url\n        }\n        configs {\n          hasDiscoveryModeEnabled\n        }\n      }\n      configs {\n        hasSeenDiscoveryModeOnboarding\n      }\n    }\n  }\n'
): (typeof documents)['\n  query DiscoveryModeConfigsQuery {\n    viewer {\n      id\n      artists {\n        id\n        name\n        image {\n          url\n        }\n        configs {\n          hasDiscoveryModeEnabled\n        }\n      }\n      configs {\n        hasSeenDiscoveryModeOnboarding\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment EligibleTracksFields on DiscoveryModeTrack {\n    enabled\n    approved\n    id\n    intentRate\n    listeners\n    listenersLift\n    saves\n    spotifyRecommend\n    streams\n    streamsLift\n    track {\n      id\n      title\n      version\n      originalArtists {\n        id\n        name\n      }\n      featuredArtists {\n        id\n        name\n      }\n      remixArtists {\n        id\n        name\n      }\n      release {\n        id\n        coverArt(size: SMALL) {\n          url\n        }\n        label {\n          id\n          name\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  fragment EligibleTracksFields on DiscoveryModeTrack {\n    enabled\n    approved\n    id\n    intentRate\n    listeners\n    listenersLift\n    saves\n    spotifyRecommend\n    streams\n    streamsLift\n    track {\n      id\n      title\n      version\n      originalArtists {\n        id\n        name\n      }\n      featuredArtists {\n        id\n        name\n      }\n      remixArtists {\n        id\n        name\n      }\n      release {\n        id\n        coverArt(size: SMALL) {\n          url\n        }\n        label {\n          id\n          name\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query DiscoveryMode($filter: DiscoveryModeTrackFilterInput) {\n    discoveryMode {\n      nextSchedulingStartsAt\n      runningCampaign {\n        id\n        title\n        startDate\n        endDate\n        state\n        schedulingStartsAt\n        schedulingEndsAt\n        eligibleTracks {\n          ...EligibleTracksFields\n        }\n      }\n      upcomingCampaign {\n        id\n        title\n        startDate\n        endDate\n        state\n        schedulingStartsAt\n        schedulingEndsAt\n        eligibleTracks(filterBy: $filter) {\n          ...EligibleTracksFields\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query DiscoveryMode($filter: DiscoveryModeTrackFilterInput) {\n    discoveryMode {\n      nextSchedulingStartsAt\n      runningCampaign {\n        id\n        title\n        startDate\n        endDate\n        state\n        schedulingStartsAt\n        schedulingEndsAt\n        eligibleTracks {\n          ...EligibleTracksFields\n        }\n      }\n      upcomingCampaign {\n        id\n        title\n        startDate\n        endDate\n        state\n        schedulingStartsAt\n        schedulingEndsAt\n        eligibleTracks(filterBy: $filter) {\n          ...EligibleTracksFields\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation removeSubscriber($labelId: ID!, $artistId: ID!) {\n    labelSubscriberRemove(input: { labelId: $labelId, artistId: $artistId }) {\n      artist {\n        id\n      }\n      errorDetails {\n        path\n        message\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation removeSubscriber($labelId: ID!, $artistId: ID!) {\n    labelSubscriberRemove(input: { labelId: $labelId, artistId: $artistId }) {\n      artist {\n        id\n      }\n      errorDetails {\n        path\n        message\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation inviteSubscriber($labelId: ID!, $artistId: ID!) {\n    labelSubscriberInvite(input: { labelId: $labelId, artistId: $artistId }) {\n      artist {\n        id\n      }\n      errorDetails {\n        path\n        message\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation inviteSubscriber($labelId: ID!, $artistId: ID!) {\n    labelSubscriberInvite(input: { labelId: $labelId, artistId: $artistId }) {\n      artist {\n        id\n      }\n      errorDetails {\n        path\n        message\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLabelAndViewer($labelId: ID!) {\n    label(id: $labelId) {\n      id\n      name\n      activeEndYear\n      activeStartYear\n      promoAccessState\n      hasPromoAccess\n      description\n      slug\n      viewer {\n        id\n        hasReleasesOnLabel\n        isLabelManager\n      }\n      genres {\n        name\n      }\n      logo(size: MEDIUM) {\n        url\n      }\n    }\n    viewer {\n      id\n      promoPool {\n        labels(filterBy: { labelId: { eq: $labelId } }) {\n          edges {\n            id\n            addedAt\n            state\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getLabelAndViewer($labelId: ID!) {\n    label(id: $labelId) {\n      id\n      name\n      activeEndYear\n      activeStartYear\n      promoAccessState\n      hasPromoAccess\n      description\n      slug\n      viewer {\n        id\n        hasReleasesOnLabel\n        isLabelManager\n      }\n      genres {\n        name\n      }\n      logo(size: MEDIUM) {\n        url\n      }\n    }\n    viewer {\n      id\n      promoPool {\n        labels(filterBy: { labelId: { eq: $labelId } }) {\n          edges {\n            id\n            addedAt\n            state\n            node {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getReleaseDSPInfo($id: ID!) {\n    release(id: $id) {\n      storeInfo {\n        name\n        url\n        status\n        __typename\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getReleaseDSPInfo($id: ID!) {\n    release(id: $id) {\n      storeInfo {\n        name\n        url\n        status\n        __typename\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getCurrentUser {\n    viewer {\n      id\n      username\n      token\n      managedLabels {\n        id\n        name\n      }\n      privacyPolicyConsents {\n        id\n        version\n        consentedAt\n      }\n      roles {\n        admin\n        artist\n        curator\n        labelManager\n        showHost\n      }\n      permissions {\n        accessPromoPool\n        proUser\n      }\n      settings {\n        enabled\n        settingType\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getCurrentUser {\n    viewer {\n      id\n      username\n      token\n      managedLabels {\n        id\n        name\n      }\n      privacyPolicyConsents {\n        id\n        version\n        consentedAt\n      }\n      roles {\n        admin\n        artist\n        curator\n        labelManager\n        showHost\n      }\n      permissions {\n        accessPromoPool\n        proUser\n      }\n      settings {\n        enabled\n        settingType\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation privacyPolicyConsent($version: ID!) {\n    privacyPolicyConsent(input: { version: $version }) {\n      privacyPolicyConsents {\n        id\n        version\n      }\n      errors\n    }\n  }\n'
): (typeof documents)['\n  mutation privacyPolicyConsent($version: ID!) {\n    privacyPolicyConsent(input: { version: $version }) {\n      privacyPolicyConsents {\n        id\n        version\n      }\n      errors\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation updateReleaseLinkState($input: ReleaseLinkStateUpdateInput!) {\n    releaseLinkStateUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      labelConfigs {\n        releaseLinkState\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation updateReleaseLinkState($input: ReleaseLinkStateUpdateInput!) {\n    releaseLinkStateUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      labelConfigs {\n        releaseLinkState\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation releaseLinkNotificationConfigUpdate($input: NotificationConfigUpdateInput!) {\n    releaseLinkNotificationConfigUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      config {\n        id\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation releaseLinkNotificationConfigUpdate($input: NotificationConfigUpdateInput!) {\n    releaseLinkNotificationConfigUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      config {\n        id\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation communicationChannelAdd($input: CommunicationChannelAddInput!) {\n    communicationChannelAdd(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        isVerified\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation communicationChannelAdd($input: CommunicationChannelAddInput!) {\n    communicationChannelAdd(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        isVerified\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation communicationChannelDeliverOtp($input: CommunicationChannelDeliverOtpInput!) {\n    communicationChannelDeliverOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        isVerified\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation communicationChannelDeliverOtp($input: CommunicationChannelDeliverOtpInput!) {\n    communicationChannelDeliverOtp(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      channel {\n        id\n        channel\n        isVerified\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query viewerNotificationConfigs {\n    viewer {\n      id\n      proUser {\n        id\n        country\n      }\n      notificationConfigs {\n        artist {\n          id\n          name\n        }\n        label {\n          id\n          name\n          configs {\n            releaseLinkFeatureEnabled\n            releaseLinkState\n          }\n        }\n        communicationChannel {\n          id\n          channel\n          value\n          isVerified\n          emailField\n        }\n        feature\n        enabled\n      }\n      communicationChannels {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'
): (typeof documents)['\n  query viewerNotificationConfigs {\n    viewer {\n      id\n      proUser {\n        id\n        country\n      }\n      notificationConfigs {\n        artist {\n          id\n          name\n        }\n        label {\n          id\n          name\n          configs {\n            releaseLinkFeatureEnabled\n            releaseLinkState\n          }\n        }\n        communicationChannel {\n          id\n          channel\n          value\n          isVerified\n          emailField\n        }\n        feature\n        enabled\n      }\n      communicationChannels {\n        id\n        channel\n        emailField\n        isVerified\n        value\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query GetPaymentSettings {\n    viewer {\n      id\n      hasPaymentPending\n      configuredPaymentMethods {\n        id\n        isValid\n        default\n        token\n        address\n        network\n        overrideRestriction\n        needsConfirmation\n        preferredPaymentProvider {\n          id\n        }\n        paymentMethod {\n          id\n          name\n          status\n          automatic\n        }\n      }\n      paymentMethods {\n        edges {\n          enabled\n          message\n          node {\n            id\n            name\n            status\n            allowOverride\n            automatic\n            needsConfirmation\n            paymentProviders {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query GetPaymentSettings {\n    viewer {\n      id\n      hasPaymentPending\n      configuredPaymentMethods {\n        id\n        isValid\n        default\n        token\n        address\n        network\n        overrideRestriction\n        needsConfirmation\n        preferredPaymentProvider {\n          id\n        }\n        paymentMethod {\n          id\n          name\n          status\n          automatic\n        }\n      }\n      paymentMethods {\n        edges {\n          enabled\n          message\n          node {\n            id\n            name\n            status\n            allowOverride\n            automatic\n            needsConfirmation\n            paymentProviders {\n              id\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query releaseLinkOnboardingStatus($labelId: ID!) {\n    label(id: $labelId) {\n      id\n      name\n      viewer {\n        shouldDisplayReleaseLinkOnboarding\n      }\n    }\n  }\n'
): (typeof documents)['\n  query releaseLinkOnboardingStatus($labelId: ID!) {\n    label(id: $labelId) {\n      id\n      name\n      viewer {\n        shouldDisplayReleaseLinkOnboarding\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation releaseLinkOnboardingSeen($input: ReleaseLinkOnboardingSeenUpdateInput!) {\n    releaseLinkOnboardingSeenUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      viewer {\n        id\n        configs {\n          hasSeenReleaseLinkOnboarding\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  mutation releaseLinkOnboardingSeen($input: ReleaseLinkOnboardingSeenUpdateInput!) {\n    releaseLinkOnboardingSeenUpdate(input: $input) {\n      errors\n      errorDetails {\n        message\n        path\n      }\n      viewer {\n        id\n        configs {\n          hasSeenReleaseLinkOnboarding\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query ReleaseArtists($releaseId: ID!) {\n    release(id: $releaseId) {\n      headlineArtists {\n        id\n        name\n      }\n    }\n  }\n'
): (typeof documents)['\n  query ReleaseArtists($releaseId: ID!) {\n    release(id: $releaseId) {\n      headlineArtists {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query PromoLabelInvites(\n    $page: Int\n    $perPage: Int\n    $filterBy: PromoSubscriptionFilterInput\n    $orderBy: PromoSubscriptionOrderInput\n    $status: LabelSubscriptionEnum\n  ) {\n    viewer {\n      id\n      promoPool {\n        labels(\n          page: $page\n          perPage: $perPage\n          filterBy: $filterBy\n          orderBy: $orderBy\n          status: $status\n        ) {\n          pageInfo {\n            pageCount\n            nodeCount\n            hasNextPage\n            hasPreviousPage\n            __typename\n          }\n          edges {\n            __typename\n            id\n            addedAt\n            hasArchiveAccess\n            state\n            node {\n              id\n              name\n              slug\n              logo(size: THUMB) {\n                url\n              }\n              topArtists {\n                id\n                name\n              }\n              genres {\n                id\n                name\n              }\n              beatportFollowerCount\n              soundcloudFollowerCount\n              __typename\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query PromoLabelInvites(\n    $page: Int\n    $perPage: Int\n    $filterBy: PromoSubscriptionFilterInput\n    $orderBy: PromoSubscriptionOrderInput\n    $status: LabelSubscriptionEnum\n  ) {\n    viewer {\n      id\n      promoPool {\n        labels(\n          page: $page\n          perPage: $perPage\n          filterBy: $filterBy\n          orderBy: $orderBy\n          status: $status\n        ) {\n          pageInfo {\n            pageCount\n            nodeCount\n            hasNextPage\n            hasPreviousPage\n            __typename\n          }\n          edges {\n            __typename\n            id\n            addedAt\n            hasArchiveAccess\n            state\n            node {\n              id\n              name\n              slug\n              logo(size: THUMB) {\n                url\n              }\n              topArtists {\n                id\n                name\n              }\n              genres {\n                id\n                name\n              }\n              beatportFollowerCount\n              soundcloudFollowerCount\n              __typename\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ArchiveAccessFields on ArtistLabelSubscription {\n    archiveAccessExpiresAt\n    archiveAccessGrantedAt\n    hasArchiveAccess\n    label {\n      id\n      name\n    }\n  }\n'
): (typeof documents)['\n  fragment ArchiveAccessFields on ArtistLabelSubscription {\n    archiveAccessExpiresAt\n    archiveAccessGrantedAt\n    hasArchiveAccess\n    label {\n      id\n      name\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation grantArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessGrant(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n'
): (typeof documents)['\n  mutation grantArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessGrant(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation renewArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessRenew(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n'
): (typeof documents)['\n  mutation renewArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessRenew(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  mutation revokeArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessRevoke(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n'
): (typeof documents)['\n  mutation revokeArchiveAccess($labelId: ID!, $artistId: ID!) {\n    archiveAccessRevoke(input: { artistId: $artistId, labelId: $labelId }) {\n      artistLabelSubscription {\n        ...ArchiveAccessFields\n      }\n      errors\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getLabelSubscribers(\n    $labelId: ID!\n    $page: Int\n    $perPage: Int\n    $sortBy: LabelSubscriberOrderInput\n    $filterBy: LabelSubscriberFilterInput\n  ) {\n    label(id: $labelId) {\n      id\n      subscribers(page: $page, perPage: $perPage, filterBy: $filterBy, orderBy: $sortBy) {\n        pageInfo {\n          page\n          pageCount\n          nodeCount\n          perPage\n          hasNextPage\n        }\n        edges {\n          id\n          addedAt\n          lastReactedAt\n          hasArchiveAccess\n          archiveAccessExpiresAt\n          archiveAccessGrantedAt\n          averageRating\n          hasPromoAccess\n          isFollowing\n          node {\n            id\n            name\n            slug\n            spotifyPopularity\n            image {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'
): (typeof documents)['\n  query getLabelSubscribers(\n    $labelId: ID!\n    $page: Int\n    $perPage: Int\n    $sortBy: LabelSubscriberOrderInput\n    $filterBy: LabelSubscriberFilterInput\n  ) {\n    label(id: $labelId) {\n      id\n      subscribers(page: $page, perPage: $perPage, filterBy: $filterBy, orderBy: $sortBy) {\n        pageInfo {\n          page\n          pageCount\n          nodeCount\n          perPage\n          hasNextPage\n        }\n        edges {\n          id\n          addedAt\n          lastReactedAt\n          hasArchiveAccess\n          archiveAccessExpiresAt\n          archiveAccessGrantedAt\n          averageRating\n          hasPromoAccess\n          isFollowing\n          node {\n            id\n            name\n            slug\n            spotifyPopularity\n            image {\n              url\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  query getRelease($id: ID!) {\n    release(id: $id) {\n      uid\n      id\n      name\n      description\n      date\n      slug\n      preorderDate\n      headlineArtists {\n        id\n        name\n        slug\n      }\n      permissions {\n        viewFeedback\n      }\n      label {\n        id\n        name\n        slug\n      }\n      coverArt(size: MEDIUM) {\n        url\n      }\n      viewer {\n        isLabelManager\n        isArtistMember\n      }\n      ...ReleaseDetailsFields\n      ...MobileButtonsReleaseFields\n    }\n  }\n'
): (typeof documents)['\n  query getRelease($id: ID!) {\n    release(id: $id) {\n      uid\n      id\n      name\n      description\n      date\n      slug\n      preorderDate\n      headlineArtists {\n        id\n        name\n        slug\n      }\n      permissions {\n        viewFeedback\n      }\n      label {\n        id\n        name\n        slug\n      }\n      coverArt(size: MEDIUM) {\n        url\n      }\n      viewer {\n        isLabelManager\n        isArtistMember\n      }\n      ...ReleaseDetailsFields\n      ...MobileButtonsReleaseFields\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment ReleaseDetailsFields on Release {\n    date\n    description\n    label {\n      name\n      slug\n    }\n    genres {\n      name\n      id\n      occurrences\n    }\n    storeInfo {\n      name\n      url\n      status\n    }\n  }\n'
): (typeof documents)['\n  fragment ReleaseDetailsFields on Release {\n    date\n    description\n    label {\n      name\n      slug\n    }\n    genres {\n      name\n      id\n      occurrences\n    }\n    storeInfo {\n      name\n      url\n      status\n    }\n  }\n'];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: '\n  fragment MobileButtonsReleaseFields on Release {\n    id\n    date\n    label {\n      name\n      slug\n    }\n  }\n'
): (typeof documents)['\n  fragment MobileButtonsReleaseFields on Release {\n    id\n    date\n    label {\n      name\n      slug\n    }\n  }\n'];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
