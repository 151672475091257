import {
  Button,
  Cell,
  Column,
  Row,
  Table,
  TableBody,
  TableHeader
} from '@protonradio/proton-ui';
import { COLORS } from '@protonradio/proton-ui/constants';

import { EligibleTracksFieldsFragment } from 'gql/graphql';
import { useDiscoveryMode } from './DiscoveryModeContext';
import Flex from 'components/Flex';
import { PacmanIcon } from 'components/Icons';
import React from 'react';
import Avatar from 'components/Avatar';
import TextHeader from 'components/TextHeader';
import CommaList from 'components/CommaList';
import { TombstoneText } from 'components/Tombstone';
import useBreakpoint from 'hooks/useBreakpoint';
import { MobileRowWrapper, StyledMobileTableHeader } from './styles';
import { useLocation } from 'react-router-dom';

interface TableComponentsProps {
  trackCampaignData: EligibleTracksFieldsFragment;
  onPress?: () => void;
  isDisabled?: boolean;
}

export const TrackScheduleButton = ({
  trackCampaignData,
  onPress,
  isDisabled
}: TableComponentsProps) => {
  const { queue } = useDiscoveryMode().scheduledQueue;
  const track = trackCampaignData.track;
  const scheduledTrack = queue.find(t => t.id === track.id);
  const trackIsLoading = scheduledTrack?.loading;

  return (
    <Button
      isDisabled={
        isDisabled ||
        !trackCampaignData.approved ||
        trackIsLoading === 'adding' ||
        trackIsLoading === 'removing'
      }
      onPress={onPress}
    >
      {trackIsLoading ? (
        <Flex align="center">
          {trackIsLoading === 'adding' ? 'Scheduling' : 'Unscheduling'}
          <PacmanIcon
            color={COLORS.GRAYSCALE.GRAY_SUPER_LIGHT}
            size={16}
            className="ml-1"
          />
        </Flex>
      ) : scheduledTrack ? (
        <span>Unschedule</span>
      ) : (
        <span>Schedule</span>
      )}
    </Button>
  );
};

export const TrackCellData = ({ trackCampaignData }: TableComponentsProps) => {
  const track = trackCampaignData.track;
  return (
    <Flex>
      <Avatar imageUrl={track.release?.coverArt?.url} />
      <div className="ml-2">
        <TextHeader
          type={TextHeader.TYPES.MEDIUM}
          weight={TextHeader.WEIGHTS.NORMAL}
          style={{ margin: 0 }}
        >
          {track.title}
        </TextHeader>
        <CommaList
          data={[
            ...track.originalArtists.map(a => a.name),
            ...track.featuredArtists.map(a => a.name)
          ]}
        />
      </div>
    </Flex>
  );
};

export const ConditionalDash = ({
  condition,
  children
}: {
  condition: boolean;
  children: React.ReactNode;
}) => {
  return !condition ? <span>–</span> : children;
};

export const TableTombstones = () => {
  const isSmallScreen = useBreakpoint(
    useBreakpoint.BREAKPOINTS.MEDIUM,
    useBreakpoint.DIRECTIONS.DOWN
  );

  const currentTab = useLocation().pathname;

  if (isSmallScreen) {
    return (
      <div aria-label="Loading table">
        <StyledMobileTableHeader className="proton-Table__header">
          {currentTab.includes('enabled') ? 'Auto Renew' : 'Scheduled'}
        </StyledMobileTableHeader>
        {[...Array(3)].map((_, i) => (
          <MobileRowWrapper key={`Tombstone ${i + 1}`}>
            <Avatar loading circle altTag="Artist avatar" size={Avatar.SIZES.MEDIUM} />
            <Flex column className="ml-1">
              <TombstoneText
                size={TombstoneText.SIZES.LARGE}
                style={{ width: '12rem', marginLeft: '1rem' }}
              />
            </Flex>
          </MobileRowWrapper>
        ))}
      </div>
    );
  }

  return (
    <Table aria-label="Loading table">
      <TableHeader>
        <Column>Track</Column>
        <Column>Label</Column>
        <Column>Remix Artists</Column>
        <Column>Intent Rate</Column>
        <Column>Scheduled</Column>
        <Column>{''}</Column>
      </TableHeader>

      <TableBody>
        {[...Array(3)].map((_, i) => (
          <Row key={`Tombstone ${i + 1}`}>
            <Cell>
              <Flex align="center">
                <Avatar loading circle />
                <TombstoneText
                  size={TombstoneText.SIZES.LARGE}
                  style={{ width: '8rem', marginLeft: '2rem' }}
                />
              </Flex>
            </Cell>
            <Cell>–</Cell>
            <Cell>–</Cell>
            <Cell>–</Cell>
            <Cell>–</Cell>
            <Cell>
              <div style={{ width: '160px' }}></div>
            </Cell>
          </Row>
        ))}
      </TableBody>
    </Table>
  );
};
